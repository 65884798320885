<template>
    <div>
        <h4 v-if="item.title" class="course__content_summary__title bold">
            {{ item.title }}
        </h4>
        <div v-if="videoLink" class="custom-player" :class="playerClasses">
            <div class="custom-player__wrap custom-player__wrap_no-wrap custom-player__wrap_border-radius-0">
                <div id="video-box">
                    <template v-if="allowDisplayVideoPlayer">
                        <LoaderBlock v-if="!langsKeys" />

                        <!-- new version of players                   -->
                        <PlayersWrapper
                            @update:link="videoLink = $event"
                            :file="item"
                            style="margin-right: -1px"
                            id="lessonPlayer"
                            :live-start-time="getWebinarDate()"
                            :live-trim-ending="lesson.is_auto_webinar ? lesson.trim_ending_seconds : 0"
                            :is-live="lesson.is_auto_webinar"
                            :lesson-id="lesson.id"
                            :uuid="item.files && item.files.length && item.files[0].uuid"
                            :lesson="lesson"
                            :video-src="videoLink || ''"
                            :info-unit-id="item.id"
                            :is-show-preview-on-pause="item.show_preview_on_pause"
                            :is-vertical="item.is_vertical"
                            :is-show-custom-youtube-player="item.show_custom_youtube_player"
                            :orientation="orientation"
                            :preview="getCurrentPreview(videoPreviews, item.preview_index, videoLink)"
                            :previews="generalModules(videoPreviews, videoLink)"
                            :previews-from-server="videoPreviews"
                            :preview-index="item.preview_index"
                            parent="lesson"
                            :auth="isAuth"
                        />
                    </template>

                    <img
                        style="min-height: 300px"
                        v-else-if="inaccessiblePreviewUrl"
                        class="lesson-inaccessible-preview"
                        :src="inaccessiblePreviewUrl"
                        :alt="lesson.title"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LessonRepository from "@lesson/shared/repository/lesson-repository"
import KeyTranslator from "@mixins/keyTranslator"
import EventBus from "~event-bus"
import CommonStore from "@common-store/common"
import LoaderBlock from "@components/Loaders/LoaderBlock"
import PlayersWrapper from "@components/Players/PlayersWrapper"
import { EventsList } from "~events"
import { timeToSecond } from "@helpers"

export default {
    name: "CoursePublicUnitsVideo",
    components: {
        PlayersWrapper,
        LoaderBlock
    },
    mixins: [KeyTranslator],
    props: {
        item: {
            type: Object,
            required: true
        },
        lesson: {
            type: Object,
            required: false
        },
        courseAccess: {
            type: Object,
            required: false
        },
        productId: {
            type: Number,
            required: false
        },
        isAuth: {
            type: Boolean,
            required: false
        },
        isFreeCourse: {
            type: Boolean,
            required: false
        },
        orderUrl: {
            type: String,
            required: false
        },
        csrfToken: {
            type: String,
            required: false
        },
        priceCourse: {
            type: String
        },
        courseTitle: {
            type: String
        },
        priceConvertCourse: {
            type: String
        }
    },
    data() {
        return {
            langsKeys: null,
            lessonUrl: null,
            inaccessiblePreviewUrl: null,
            allowDisplayVideoPlayer: true,
            videoPreviews: [],
            videoLink: null,
            orientation: "horizontal"
        }
    },
    computed: {
        getTemplateModules() {
            return (CommonStore && CommonStore.state.templateModules) || []
        },
        langs() {
            return this.$store && this.$store.getters["langs/getLangs"]
        },
        playerClasses() {
            let classes = {}

            if (this.item.desktop_player_size) {
                classes[this.item.desktop_player_size] = true
            }

            return classes
        }
    },
    methods: {
        getDrippingAccessDate(lesson) {
            const firstInacLesson = this.courseAccess.firstInaccessibleLesson
            if (lesson.accessible_after_data) {
                return lesson.accessible_after_data.access_date
            } else if (firstInacLesson && firstInacLesson.accessible_after_data) {
                return firstInacLesson.accessible_after_data.access_date
            } else {
                return ""
            }
        },
        getDrippingAccessibleAfterDataKey() {
            const firstInacLesson = this.courseAccess.firstInaccessibleLesson
            return (
                (firstInacLesson &&
                    firstInacLesson.accessible_after_data &&
                    firstInacLesson.accessible_after_data.type) ||
                ""
            )
        },
        getDrippingAccessibleAfterDataName() {
            const firstInacLesson = this.courseAccess.firstInaccessibleLesson
            return (
                (firstInacLesson &&
                    firstInacLesson.accessible_after_data &&
                    firstInacLesson.accessible_after_data.checkpoint_name) ||
                ""
            )
        },
        getWebinarDate() {
            return (
                (this.lesson.is_auto_webinar &&
                    this.lesson.accessible_after_data &&
                    this.lesson.accessible_after_data.access_date) ||
                null
            )
        },
        eventsStarted() {
            fetch(`/api/v1/lessons/${this.lesson.id}/events/started`, {
                method: "post",
                headers: {
                    Authorization: window.token ? `Bearer ${window.token}` : ""
                }
            }).then(() => {
                setTimeout(() => {
                    this.eventsWatched()
                }, 5000)
            })
        },
        eventsWatched() {
            fetch(`/api/v1/lessons/${this.lesson.id}/events/watched`, {
                method: "post",
                headers: {
                    Authorization: window.token ? `Bearer ${window.token}` : ""
                }
            })

            if (this.lesson.section_id) {
                LessonRepository.replaceContents({ section: this.lesson.section_id })
            }
        },
        startCheckDripping() {
            const lesson = this.lesson
            const courseAccess = this.courseAccess
            const courseId = lesson.course_id
            let options = null
            if (!lesson.is_public && courseAccess.is_course_available_in_future) {
                this.inaccessiblePreviewUrl = lesson.preview
                options = {
                    courseId: courseId,
                    cover: "lesson",
                    modalName: lesson.is_auto_webinar ? "video-live" : "content-future",
                    skipCheckAccessible: true
                }
            } else if ((lesson.is_public || courseAccess.is_course_available) && lesson.is_text_content) {
                this.inaccessiblePreviewUrl = (lesson.previews && lesson.previews[0]?.url) || null
                if (courseAccess.is_course_subscribed) {
                    this.eventsStarted()
                }
            } else if ((lesson.is_public || courseAccess.is_course_available) && !lesson.is_text_content) {
                if (!courseAccess.is_course_subscribed && !lesson.is_public) {
                    this.inaccessiblePreviewUrl = lesson.preview
                    options = {
                        courseId: courseId,
                        cover: "lesson",
                        modalName: "video-purchase",
                        skipCheckAccessible: true
                    }
                } else if (!courseAccess.available_lessons_ids.includes(lesson.id) && !courseAccess.is_user_expert) {
                    this.inaccessiblePreviewUrl = lesson.preview
                    options = {
                        courseId: courseId,
                        cover: "lesson",
                        modalName: "video-content",
                        skipCheckAccessible: true
                    }
                } else {
                    this.allowDisplayVideoPlayer = true
                }
            } else {
                this.inaccessiblePreviewUrl = lesson.preview
                options = {
                    courseId: courseId,
                    modalName: "video-purchase",
                    cover: "lesson",
                    skipCheckAccessible: true
                }
            }
            if (options && !this.allowDisplayVideoPlayer) {
                //window.showVideoBlockNew(null, options)
            }
        },
        getLangKeys() {
            let timer = null
            if (this.langs) {
                this.langsKeys = this.langs
                return
            }
            timer = setInterval(() => {
                if (window.langs) {
                    this.langsKeys = window.langs
                    clearInterval(timer)
                }
            }, 1000)
        },
        sortGeneralModules(array = []) {
            const previews = array
                .filter(p => p !== null && !p.id)
                .map(p => (typeof p === "string" ? { path: p } : p))
                .reduce((res, item) => {
                    if (!res.find(p => (p.path || p.url) === (item.path || item.url))) {
                        res.push(item)
                    }
                    return res
                }, [])
            const previewsFromServer = array.filter(p => p !== null && p.id && (p.path || p.url))
            return [...previews, ...previewsFromServer]
        },
        getCurrentPreview(lessonPreviews = [], previewIndex = 0, url = "") {
            /*if (this.checkYouTubeUrl(url)) {
                lessonPreviews = [
                    "https://img.youtube.com/vi/wRZrdrGfqmU/maxres1.jpg",
                    "https://img.youtube.com/vi/wRZrdrGfqmU/maxres1.jpg",
                    ...lessonPreviews
                ]
                url = ""
            }*/

            const preview = this.generalModules(lessonPreviews, url)[previewIndex ?? 0]

            return preview ? preview.url || preview.path : null
        },
        getPreviews() {
            let previews = this.item.files.filter(f => f.type_id !== 3)
            const videoFile = this.item.files.find(f => f.type_id === 3)
            if (videoFile && videoFile.children.length) {
                const previewsArr = videoFile.children.filter(f => f.type_id !== 3)
                previews = [...previewsArr, ...previews]
            }
            this.videoPreviews = previews
        },
        getVideoLink() {
            if (this.item.playback_url) {
                this.videoLink = this.item.playback_url
            } else {
                const file = this.item.files.find(f => f.type_id === 3)
                if (file) {
                    this.videoLink = file.url
                    this.orientation = file.orientation || "horizontal"
                }
            }
        },
        extractYoutubeKey(url) {
            const regExp =
                /^.*(?:(?:youtu\.be\/|v\/|vi\/|live\/|u\/\w\/|embed|shorts|\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]*).*/
            let match = url.match(regExp)
            let videoId = null
            if (match) {
                if (match[7] && match[7].length === 11) {
                    videoId = match[7]
                } else if (match[1] && match[1].length === 11) {
                    videoId = match[1]
                }
            }
            return videoId || false
        },
        generalModules(lessonPreviews = [], url = "") {
            if (!lessonPreviews.length && !this.checkYouTubeUrl(url)) {
                return lessonPreviews
            }
            const items = this.sortGeneralModules(lessonPreviews)

            const isFirstPreviewYoutube = () => {
                return (
                    lessonPreviews[0] &&
                    typeof lessonPreviews[0] === "string" &&
                    lessonPreviews[0].includes("img.youtube")
                )
            }

            const youtubePreviews =
                this.checkYouTubeUrl(url) && !isFirstPreviewYoutube()
                    ? [
                          {
                              url: `https://img.youtube.com/vi/${this.extractYoutubeKey(url)}/sddefault.jpg`
                          },
                          {
                              url: `https://img.youtube.com/vi/${this.extractYoutubeKey(url)}/maxres1.jpg`
                          }
                      ]
                    : []
            return [...youtubePreviews, ...items, ...this.getTemplateModules]
        },
        checkYouTubeUrl(url) {
            return !!(
                url &&
                url.match(
                    "^.*(?:(?:youtu\\.be\\/|v\\/|vi\\/|live\\/|u\\/\\w\\/|embed\\/)|(?:(?:watch)?\\?v(?:i)?=|\\&v(?:i)?=))([^#\\&\\?]*).*"
                ) &&
                !url.match("ahacdn|ahvcdn|kwiga")
            )
        },
        setSeekTo(unitId, sec) {
            EventBus.$emit(EventsList.VIDEO_SET_SEEK, {
                unitId: unitId,
                seekTo: sec
            })
        },
        parseItemSeekTo() {
            // get units wrapper
            let $parents = this.$parent
            if ($parents) {
                // get units node
                $parents = $parents.$el
                const $links = $parents.querySelectorAll("a[href]")
                for (let l = 0; l < $links.length; l++) {
                    const $link = $links[l]
                    // get query params
                    const hashStr = new URL($link.href).hash
                    const params = new URLSearchParams(hashStr.substring(1))
                    if (!hashStr || !params.has("video-id")) {
                        continue
                    }
                    const [unitId, time] = params.get("video-id").split("/")
                    // parse seconds
                    const seconds = timeToSecond(time, false)
                    if (!unitId || seconds === undefined) {
                        continue
                    }
                    $link.setAttribute("data-start", seconds)
                    $link.removeAttribute("target")
                    $link.onclick = e => {
                        e.preventDefault()
                        e.stopPropagation()
                        window.location = hashStr
                        this.setSeekTo(+unitId, seconds)
                    }
                }
            }
        }
    },
    created() {
        this.getVideoLink()
        this.getPreviews()
        EventBus.$on(EventsList.GET_YOUTUBE_PREVIEWS, ({ id, previews }) => {
            if (this.videoLink && this.videoLink.includes(id)) {
                this.videoPreviews = [...previews, ...this.videoPreviews]
            }
        })
    },
    mounted() {
        this.lessonUrl = window.location.href
        this.getLangKeys()
        //this.startCheckDripping()
        setTimeout(() => {
            this.$nextTick(() => {
                this.parseItemSeekTo()
            })
        }, 1000)
    }
}
</script>

<style lang="sass">
@media (min-width: 1024px)
    .width-75
        width: 75%
</style>


