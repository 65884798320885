import { BrowserTracing } from "@sentry/tracing"
import * as Sentry from "@sentry/vue"

export default function (Vue, router, user = null) {
    if (!window.is_prod) {
        return
    }

    if (user) {
        Sentry.setUser({
            name: user.name,
            email: user.email,
            id: user.id
        })
    } else {
        Sentry.setUser(null)
    }

    const { sentry_dsn, sentry_enabled } = window

    if (!sentry_dsn || !sentry_enabled || !Vue) {
        return
    }

    const integrations = []

    if (router) {
        integrations.push(
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracePropagationTargets: ["localhost", "my-site-url.com", /^\//]
            })
        )
    }

    /*    integrations.push(
            new CaptureConsole({
                levels: ["error"]
            })
        )*/

    // integrations.push(new Sentry.Replay())

    Sentry.init({
        logErrors: true,
        Vue,
        dsn: sentry_dsn,
        integrations,
        tracesSampleRate: 0.0,
        replaysSessionSampleRate: 0.0,
        replaysOnErrorSampleRate: 1.0,
        ignoreErrors: [
            // Random plugins/extensions
            "top.GLOBALS",
            // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
            "originalCreateNotification",
            "canvas.contentDocument",
            "MyApp_RemoveAllHighlights",
            "http://tt.epicplay.com",
            "Can't find variable: ZiteReader",
            "jigsaw is not defined",
            "ComboSearch is not defined",
            "http://loading.retry.widdit.com/",
            "atomicFindClose",
            // Facebook borked
            "fb_xd_fragment",
            // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
            // reduce this. (thanks @acdha)
            // See http://stackoverflow.com/questions/4113268
            "bmi_SafeAddOnload",
            "Non-Error promise rejection captured",
            "Object captured as promise rejection with keys: code, message",
            "EBCallBackMessageReceived",
            "BufferLoader: XHR error for undefined",
            // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
            "conduitPage",
            /.+vimeo.+ is not embeddable.$/,
            "TypeError: Failed to fetch",
            "Request aborted",
            "ReferenceError: r is not defined",
            "Uncaught ReferenceError: r is not defined",
            "ReferenceError: Can't find variable: r",
            "ReferenceError: t is not defined",
            "Uncaught ReferenceError: t is not defined",
            "ReferenceError: Can't find variable: t",
            "There is already an encoder stored which handles exactly the same mime types.",
            "NavigationDuplicated",
            "Error: Request failed with status code 422",
            "Error: Network Error",
            "AbortError: The operation was aborted.",
            "Load failed"
        ],
        denyUrls: [
            // Facebook flakiness
            /graph\.facebook\.com/i,
            // Facebook blocked
            /connect\.facebook\.net\/en_US\/all\.js/i,
            // Woopra flakiness
            /eatdifferent\.com\.woopra-ns\.com/i,
            /static\.woopra\.com\/js\/woopra\.js/i,
            // Chrome extensions
            /extensions\//i,
            /^chrome:\/\//i,
            /^chrome-extension:\/\//i,
            // Other plugins
            /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
            /webappstoolbarba\.texthelp\.com\//i,
            /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
        ]
    })
}
