<template>
    <div v-click-outside="hide" class="fill-gaps-dropdown" :class="modifier">
        <template v-if="!isFlat">
            <div
                @click="open"
                :class="{
                    open: isOpen,
                    val: active.text,
                    'pointer-events-none': !interactive
                }"
                :style="{ color: active.text ? '#151515' : '#B5C1D2' }"
                class="cursor-pointer handler"
            >
                <span
                    @click="
                        active = { id: 0, text: '' }
                        $emit('delete')
                    "
                    v-if="isAllAnswersChoice && active.text && interactive"
                    class="delete"
                >
                    <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M8.33331 1.16602L1.66663 7.8327M1.66665 1.16602L8.33333 7.8327"
                            stroke="#3E4755"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </span>
                <template v-if="active.text">
                    {{ active.text }}
                </template>
                <span class="placeholder" v-else>
                    {{ t("quiz.select_answer") }}
                </span>
                <svg
                    v-if="interactive"
                    class="arrow"
                    :style="{ transform: isOpen ? 'rotate(180deg)' : 'none' }"
                    width="12"
                    height="7"
                    viewBox="0 0 12 7"
                    stroke="#3E4755"
                    fill="none"
                >
                    <path d="M1 1L6 6L11 1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </div>
            <transition name="fade-up">
                <div v-if="isOpen && cases.length" class="fill-gaps-dropdown__modal">
                    <vue-simplebar style="max-height: 220px; overflow: auto">
                        <DefaultDropdownOption
                            class="mr-7"
                            @select="
                                hide()
                                active = $event.opt
                                $emit('update', {
                                    text_answer: $event.opt.text,
                                    answer_id: $event.opt.id
                                })
                            "
                            :default-val="active"
                            :class="{
                                'pointer-events-none': !interactive
                            }"
                            :option="option"
                            :key="index"
                            v-for="(option, index) in cases"
                            key-name="text"
                        />
                    </vue-simplebar>
                </div>
            </transition>
        </template>
        <template v-else>
            <template v-for="(caseValue, index) in cases">
                <span
                    @click="handleClickCase(caseValue)"
                    :class="{ active: isActive(caseValue), 'pointer-events-none': !interactive }"
                    class="fill-gaps-dropdown-case"
                    :key="caseValue.id"
                >
                    {{ caseValue.text }}
                </span>
                <span v-if="index !== cases.length - 1" :key="`${caseValue.id}-slash`">/</span>
            </template>
        </template>
    </div>
</template>

<script>
import DefaultDropdownOption from "../../../Forms/DefaultDropdownOption.vue"
import IsOpen from "../../../../mixins/is-open"
import { VueSimplebar } from "vue-simplebar"

export default {
    components: { DefaultDropdownOption, VueSimplebar },
    mixins: [IsOpen],
    props: {
        cases: {
            type: Array,
            default: () => []
        },
        interactive: {
            type: Boolean,
            default: true
        },
        viewable: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        isFlat: {
            type: Boolean,
            default: false
        },
        typeShow: {
            type: String,
            default: ""
        },
        result: {
            type: Object,
            default: null
        },
        isAllAnswersChoice: {
            type: Boolean,
            default: false
        },
        answerGroup: {
            type: Object,
            default: null
        }
    },
    name: "PracticeFillGapsDropdown",
    data() {
        return {
            active: {
                text: "",
                id: 0
            },
            modifier: ""
        }
    },
    created() {
        if (!this.interactive || this.viewable) {
            this.active.text = this.result?.text_answer || ""
            this.active.id = this.result?.quiz_answer_id || 0
            if (
                this.typeShow !== "simple_answer" &&
                this.typeShow !== "only_correct_or_not_question" &&
                this.result &&
                !this.viewable
            ) {
                this.modifier = this.result.is_correct ? "green" : "red"
            }
        }
        if (this.viewable && this.active.id) {
            this.$emit("update", {
                text_answer: this.active.text,
                answer_id: this.active.id
            })
        }

        if (this.answerGroup && this.answerGroup.active) {
            this.active.text = this.answerGroup.active.text_answer || ""
            this.active.id = this.answerGroup.active?.answer_id || 0
        }
    },
    methods: {
        handleClickCase(caseValue) {
            if (this.isActive(caseValue)) {
                this.$emit("delete")
                this.active = { id: 0, text: "" }
            } else {
                this.$emit("update", {
                    text_answer: caseValue.text,
                    answer_id: caseValue.id
                })
                this.active = caseValue
            }
        },
        isActive(value) {
            if (!this.active) {
                return false
            }

            return this.active?.id === value?.id || this.active?.answer_id === value?.id
        }
    }
}
</script>

<style scoped lang="sass">
.fill-gaps-dropdown-case
    color: #98A2B0
    cursor: pointer
    &.active
        color: #3965FF
.fill-gaps-dropdown
    display: inline-block
    position: relative
    .placeholder
        color: #B5C1D2 !important
    &::v-deep
        .simplebar-vertical
            width: 4px !important
    &__modal
        position: absolute
        left: -1px
        top: 30px
        width: calc(100% + 2px)
        min-width: 250px
        background-color: #fff
        padding: 10px 4px 10px 8px
        z-index: 999
        box-shadow: 0 4px 10px rgba(128, 158, 191, 0.15)
        border-radius: 10px
        border: 1px solid #fff
        margin-bottom: 10px
    .handler
        margin-left: 4px
        margin-right: 4px
        padding-bottom: 6px
        margin-bottom: 8px
        border-bottom: 1px solid #98A2B0

        .delete
            width: 20px
            height: 20px
            border-radius: 4px
            box-shadow: 0px 2px 5px 0px #809EBF40
            margin-right: 10px
            display: inline-flex
            align-items: center
            justify-content: center
            cursor: pointer
            path
                transition: .2s
            &:hover
                path
                    stroke: #DD4141

        .arrow
            transition: .2s
            margin-bottom: 1px
            margin-right: 5px
            margin-left: 10px

        &:hover, &.open
            border-color: #3965FF

            .arrow
                stroke: #3965FF

        &.val
            border-color: #3965FF

    &.red
        .fill-gaps-dropdown-case.active
            color: #DD4141 !important
        .handler
            color: #DD4141 !important
            border-color: #DD4141 !important

            .arrow
                stroke: #3E4755

    &.green
        .fill-gaps-dropdown-case.active
            color: #15A06E !important
        .handler
            color: #15A06E !important
            border-color: #15A06E !important

            .arrow
                stroke: #3E4755

    .default-dropdown
        display: inline-block
        min-width: 0

    &::v-deep
        .default-dropdown__modal
            top: 30px
            min-width: 192px
</style>
